<template>
  <div
    class="view-catalog-list"
    v-if="!loading">
    <!-- Intro -->
    <div
      class="view-catalog-list__intro"
      v-scroll-reveal>
      <div class="view-catalog-list__intro-content">
        <actions-button
          :appearance="$pepper.Appearance.SUBTLE"
          icon-pre="arrow-left"
          :href="servicesRoute"
          v-if="hasMultipleServices && !isEatin"
        >{{ $t('resto.selection_service') }}</actions-button>

        <h2 class="view-catalog-list__intro-title">{{ title }}</h2>
      </div>
    </div>

    <!-- Content -->
    <div class="container">
      <div class="-cols">
        <!-- Body -->
        <div
          class="col flow view-catalog-body"
          :class="{ '-is-loading': isCatalogLoading }">
          <div
            class="view-catalog-body__loader flow"
            v-if="isCatalogLoading">
            <ui-loader />
          </div>

          <!-- Header -->
          <header
            class="view-catalog-header"
            v-if="isSearchEnabled || hasCategoryNavigation">

            <!-- Search & Filters bar -->
            <catalog-filters
              class="view-catalog-header__filters"
              v-if="isSearchEnabled"
              v-scroll-reveal
            />

            <!-- Categories -->
            <div
              class="view-catalog-header__categories"
              ref="scroll"
              v-if="hasCategoryNavigation">
              <nav-categories
                :back="back"
                :category="category"
                @back="onCategory(null)"
                @category="onCategory"
                v-if="!isCatalogLoading"
                v-scroll-reveal
              />
            </div>
          </header>

          <!-- Template -->
          <!-- :class="{ 'view-catalog-body__is-loading': isCatalogLoading }" -->
          <component
            :is="catalogTemplate"
            @back="onCategory(null)"
            @category="onCategory"
          />
        </div>

        <!-- Aside -->
        <aside class="col flow view-catalog-list__aside -desktop">
          <div class="view-catalog-list__aside-body">
            <!-- Cart -->
            <catalog-cart
              v-if="!isCatalogReadonly"
              v-scroll-reveal
            />

            <!-- Alternative Category Navigation -->
            <nav-categories-aside
              @category="onCategory"
              v-if="isCategoryAside && !isCatalogLoading"
            />
          </div>
        </aside>
      </div>
    </div>

    <!-- Children -->
    <router-view :key="$route.path" />
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import { basil } from '@spices/basil'

import CatalogCart from '@/modules/catalog/components/cart/cart-container'
import CatalogFilters from '@/modules/catalog/components/catalog/filters'
import CatalogTemplateSpa from '@/modules/catalog/components/templates/spa'
import CatalogTemplateHierarchy from '@/modules/catalog/components/templates/hierarchy'
import CatalogToolbar from '@/modules/catalog/components/catalog/toolbar'
import NavCategories from '@/modules/catalog/components/ui/nav-categories'
import NavCategoriesAside from '@/modules/catalog/components/ui/nav-categories-aside'

export default {
  name: 'CatalogView',

  components: {
    CatalogCart,
    CatalogFilters,
    CatalogToolbar,
    NavCategories,
    NavCategoriesAside,
  },

  inject: [
    '$core',
    '$catalog',
    '$cart',
    '$challenge',
    '$embed',
    '$localStorage'
  ],

  data() {
    return {
      loading: true,
    }
  },

  computed: {
    ...mapState({
      cart: state => state['sayl-front.cart'].cart,
      catalog: state => state['sayl-front.catalog'].catalog,
      category: state => state['sayl-front.catalog'].category,
      categories: state => state['sayl-front.catalog'].categories,
      service: state => state['sayl-front.service'].service,
      services: state => state['sayl-front.service'].services,
    }),

    ...mapGetters({
      oat: 'bootstrap/oat',
    }),

    back() {
      return this.isHierarchy && this.hasCategories ?
             this.$t('resto.catalog_back_all_categories') :
             null
    },

    catalogTemplate() {
      let ret = null;
      switch(this.oat.template){
        case 'hierarchy':
          ret = CatalogTemplateHierarchy
          break;

        case 'spa':
          default:
          ret = CatalogTemplateSpa
          break;
      }

      return ret;
    },

    hasCategories() {
      return this.categories && this.categories.length > 1
    },

    embed() {
      return this.$basil.get(this.$embed, 'embed.model')
    },

    hasCategoryNavigation() {
      return this.isHierarchy && this.hasCategory && this.isCategoryTop ||
             this.isSpa && this.isCategoryTop
    },

    hasCategory() {
      return !!this.category
    },

    hasMultipleServices() {
      return this.services && this.services.length > 1
    },

    isCatalogReadonly() {
      return this.$basil.get(this.oat, 'is_catalog_readonly', false)
    },

    isCategoryAside() {
      return this.$basil.get(this.oat, 'category_navigation', 'top') === 'aside'
    },

    isCategoryTop() {
      return this.$basil.get(this.oat, 'category_navigation', 'top') === 'top'
    },

    isEatin() {
      return this.$basil.get(this.service, 'name', null) === 'eatin'
    },

    isHierarchy() {
      return this.oat.template === 'hierarchy'
    },

    isCatalogLoading() {
      return this.$catalog.loading
    },

    isSearchEnabled() {
      return this.$basil.get(this.oat, 'has_search', false) === true
    },

    isSpa() {
      return this.oat.template === 'spa'
    },

    servicesRoute() {
      return {name: 'sayl-front-catalog.services'}
    },

    title() {
      return this.$t('resto.catalog_menu_title');
    }
  },

  methods: {
    ...mapMutations({
      setCategory: 'sayl-front.catalog/setCategory'
    }),

    onCategory(category){
      if(category && this.category && (category?.id ?? null) === (this.category?.id ?? null)) {
        this.$bus.$emit('category:top')
      }
      this.setCategory(category);

      if (!this.$basil.isNil(category)){
        this.$bus.$emit('injekt.sp.category', category.id);
      }
    }
  },

  mounted() {
    this.loading = true

    let table = this.$localStorage.table
    let mode = this.$localStorage.mode

    // No Service set
    if(basil.isNil(this.service)) {
      this.$router.replace({ name: 'sayl-front-catalog.services', params: {embed: this.embed.id}, query: this.$router.query }).catch(() => {})
      return
    }

    // No table set and we are in eatin
    if(this.service.name === 'eatin' && mode !== 'tt') {
      (basil.isNil(table) || table === false) && this.$bus.$emit('table:null', true);
    }

    // Verify if the catalog is still the right one
    this.$catalog.init({ id: this.$basil.get(this.service, 'menuId'), service: this.$localStorage.service })
      .catch((e) => $console.error(e))

    this.loading = false
  },
}
</script>
